import Repo from './_repo.js'

var indexingPromise = null
var listCache = {}

export default class extends Repo {
  static async create(data, t) {
    const board = await t.board('id')
    const params = Object.assign({ idBoard: board.id }, data)
    const list = await $tpu.r.data.restFetch(`/lists`, 'POST', params)
    if (list) { // Success
      listCache[list.id] = list
      return list
    }
    return null
  }

  static _all(t) {
    if (this.isInTrello()) {
      return t.lists('all')
    }
    return new Promise(
      (resolve, reject) => {
        resolve([{
          id: 'list1',
          name: 'List1'
        },{
          id: 'list2',
          name: 'List2'
        },{
          id: 'list3',
          name: 'List3'
        }])
      }
    )
  }

  static registry(t) {
    if (indexingPromise) {
      // console.debug("Reusing list indexing promise...")
      return indexingPromise
    }

    // console.debug("Fetching new indexed lists...")

    indexingPromise = new Promise((resolve, reject) => {
      const indexedLists = {}
      this._all(t).then((lists) => {
        lists.forEach((list, index) => {
          indexedLists[list.id] = list
          list.shapeUp = { index: index }
        })
        indexingPromise = null
        listCache = indexedLists
        resolve(indexedLists)
      })
    })
    return indexingPromise
  }

  static first() {
    const firstKey = Object.keys(listCache)[0]
    return listCache[firstKey]
  }

  static find(listId) {
    return listCache[listId]
  }

  static findAll() {
    return Object.values(listCache)
  }
}
